import {useEffect, useState} from "react";
import styled from "styled-components";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import {LAMPORTS_PER_SOL, PublicKey, Connection } from "@solana/web3.js";
import { programs } from "@metaplex/js";
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {GatewayProvider} from '@civic/solana-gateway-react';
import Countdown from "react-countdown";
import {Snackbar, Paper, LinearProgress, Chip, useMediaQuery, CircularProgress} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {toDate, AlertState, getAtaForMint} from './utils';
import {MintButton} from './MintButton';
import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken,
    CANDY_MACHINE_PROGRAM,
} from "./candy-machine";
import "@google/model-viewer/dist/model-viewer"
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': any & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> ;
    }
  }
}

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";



const WalletAmount = styled.div`
  color: black;
  width: auto;
  padding: 5px 5px 5px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 22px;
  background-color: var(--main-text-color);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: flex-start;
  gap: 10px;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin-top: 10px;
  padding: 0;
`;

const ConnectButton = styled(WalletMultiButton)`
  border-radius: 18px !important;
  padding: 6px 16px;
  background-color: #4E44CE;
  margin: 0 auto;
`;

const NFT = styled(Paper)`
  padding: 5px 20px 20px 20px;
  flex: 1 1 auto;
  background-color: #ffffff70 !important;
  backdrop-filter: blur(10px);
  color: black !important;
`;


const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--main-background-color) !important;
  margin: 5px;
  min-width: 40px;
  padding: 24px;
  color: black !important;
  h1{
    margin:0px;
  }
`;

const MintButtonContainer = styled.div`
  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }
`;


const SolExplorerLink = styled.a`
  color: var(--title-text-color);
  border-bottom: 1px solid var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;

  :hover {
    border-bottom: 2px solid var(--title-text-color);
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 4%;
  margin-left: 4%;
  text-align: center;
  justify-content: center;
`;

const MintContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 20px;
`;

const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;
  margin-bottom: 20px;
`;

const Price = styled(Chip)`
  margin: 20px 5px 0 0;
  font-weight: bold;
  z-index: 1;
  font-size: 1.1em !important;
`;


const BorderLinearProgress = styled(LinearProgress)`
  margin: 25px;
  height: 10px !important;
  border-radius: 30px;
  border: 2px solid #ffffff70;
  box-shadow: 5px 5px 40px 5px rgba(0,0,0,0.5);
  background-color:#ffffff70 !important;
  
  > div.MuiLinearProgress-barColorPrimary{
    background-color:var(--title-text-color) !important;
  }

  > div.MuiLinearProgress-bar1Determinate {
    border-radius: 30px !important;
  }
`;



export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
}
const ImgWithProgress = ({url}:{url: string}) => {
  const [done, setDone] = useState(false);
  return(<>
  {!done&&<CircularProgress/>}
  <img src={url}
    alt="NFT preview"
    style={{width: "60%", borderRadius: "20px"}}
    onLoad={()=>{setDone(true)}}
  />
  </>)
}
const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
    const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [payWithSplToken, setPayWithSplToken] = useState(false);
    const [price, setPrice] = useState(1440);
    const [priceLabel, setPriceLabel] = useState<string>("MM");
    const [whitelistPrice, setWhitelistPrice] = useState(0);
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);
    const [isBurnToken, setIsBurnToken] = useState(false);
    const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
    const [nftMetadatas, setNFTMetadatas] = useState<any[]>([]);
    const desktop = useMediaQuery('(min-width:700px)');
    useEffect(() => {
      const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
      const fetchMetadata = async () => {
        if (solanaExplorerLink !== ""){
          try{const {
            metadata: { Metadata }
          } = programs;
          const connection = new Connection(cluster === "devnet" ? "https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899/" : "https://ssc-dao.genesysgo.net/", "finalized");
          const tokenMint = solanaExplorerLink.split('/')[4].split('?')[0];
          const metadataPDA = await Metadata.getPDA(new PublicKey(tokenMint));
          const tokenMetadata = await Metadata.load(connection, metadataPDA);
          const response = await fetch(tokenMetadata.data.data.uri)
          const json = await response.json();
          console.log(json)
          setNFTMetadatas([...nftMetadatas, json])
        }
          catch{
            console.log("error fetching metadata");
            await delay(3000);
            fetchMetadata()
          }
        }
        
      }
      fetchMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [solanaExplorerLink]);
    const [isEnded, setIsEnded] = useState(false);
    const [endDate, setEndDate] = useState<Date>();
    const [isPresale, setIsPresale] = useState(false);
    const [isWLOnly, setIsWLOnly] = useState(false);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });
  //   const metadataPDA = await Metadata.getPDA(new PublicKey(tokenMint));
  // const tokenMetadata = await Metadata.load(connection, metadataPDA);
    const wallet = useAnchorWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachine>();

    const rpcUrl = props.rpcHost;

    const refreshCandyMachineState = () => {
        (async () => {
            if (!wallet) return;

            const cndy = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection
            );

            setCandyMachine(cndy);
            setItemsAvailable(cndy.state.itemsAvailable);
            setItemsRemaining(cndy.state.itemsRemaining);
            setItemsRedeemed(cndy.state.itemsRedeemed);

            var divider = 1;
            if (decimals) {
                divider = +('1' + new Array(decimals).join('0').slice() + '0');
            }

            // detect if using spl-token to mint
            if (cndy.state.tokenMint) {
                setPayWithSplToken(true);
                // Customize your SPL-TOKEN Label HERE
                // TODO: get spl-token metadata name
                // setPriceLabel(splTokenName);
                // setPrice(cndy.state.price.toNumber() / divider);
                setWhitelistPrice(cndy.state.price.toNumber() / divider);
            }else {
                setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
            }


            // fetch whitelist token balance
            if (cndy.state.whitelistMintSettings) {
                setWhitelistEnabled(true);
                setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
                setIsPresale(cndy.state.whitelistMintSettings.presale);
                setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

                if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
                    if (cndy.state.tokenMint) {
                        setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
                    } else {
                        setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
                    }
                }

                let balance = 0;
                try {
                    const tokenBalance =
                        await props.connection.getTokenAccountBalance(
                            (
                                await getAtaForMint(
                                    cndy.state.whitelistMintSettings.mint,
                                    wallet.publicKey,
                                )
                            )[0],
                        );

                    balance = tokenBalance?.value?.uiAmount || 0;
                } catch (e) {
                    console.error(e);
                    balance = 0;
                }
                setWhitelistTokenBalance(balance);
                setIsActive(isPresale && !isEnded && balance > 0);
            } else {
                setWhitelistEnabled(false);
            }

            // end the mint when date is reached
            if (cndy?.state.endSettings?.endSettingType.date) {
                setEndDate(toDate(cndy.state.endSettings.number));
                if (
                    cndy.state.endSettings.number.toNumber() <
                    new Date().getTime() / 1000
                ) {
                    setIsEnded(true);
                    setIsActive(false);
                }
            }
            // end the mint when amount is reached
            if (cndy?.state.endSettings?.endSettingType.amount) {
                let limit = Math.min(
                    cndy.state.endSettings.number.toNumber(),
                    cndy.state.itemsAvailable,
                );
                setItemsAvailable(limit);
                if (cndy.state.itemsRedeemed < limit) {
                    setItemsRemaining(limit - cndy.state.itemsRedeemed);
                } else {
                    setItemsRemaining(0);
                    cndy.state.isSoldOut = true;
                    setIsEnded(true);
                }
            } else {
                setItemsRemaining(cndy.state.itemsRemaining);
            }

            if (cndy.state.isSoldOut) {
                setIsActive(false);
            }
        })();
    };

    const renderGoLiveDateCounter = ({days, hours, minutes, seconds}: any) => {
        return (
            <div>
              {days > 0 && <Card elevation={1}><h1 style={{margin:1}}>{days}</h1><br/>Days</Card>}
              {hours > 0 && <Card elevation={1}><h1 style={{margin:1}}>{hours}</h1><br/>Hours</Card>}
              {minutes > 0 && <Card elevation={1}><h1 style={{margin:1}}>{minutes}</h1><br/>Minutes</Card>}
              <Card elevation={1}><h1 style={{margin:1}}>{seconds}</h1><br/>Seconds</Card>
            </div>
        );
    };

    const renderEndDateCounter = ({days, hours, minutes}: any) => {
        let label = "";
        if (days > 0) {
            label += days + " days "
        }
        if (hours > 0) {
            label += hours + " hours "
        }
        label += (minutes+1) + " minutes left to MINT."
        return (
            <div><h3>{label}</h3></div>
        );
    };

    function displaySuccess(mintPublicKey: any): void {
        let remaining = itemsRemaining - 1;
        setItemsRemaining(remaining);
        setIsSoldOut(remaining === 0);
        if (isBurnToken && whitelistTokenBalance && whitelistTokenBalance > 0) {
            let balance = whitelistTokenBalance - 1;
            setWhitelistTokenBalance(balance);
            setIsActive(isPresale && !isEnded && balance > 0);
        }
        setItemsRedeemed(itemsRedeemed + 1);
        const solFeesEstimation = 0.012; // approx
        if (!payWithSplToken && balance && balance > 0) {
            setBalance(balance - (whitelistEnabled ? whitelistPrice : price) - solFeesEstimation);
        }
        setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
            ? ("https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster)
            : ("https://solscan.io/token/" + mintPublicKey));
        throwConfetti();
    };

    function throwConfetti(): void {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: {y: 0.6},
        });
    }

    const onMint = async () => {
        try {
            setIsMinting(true);
            if (wallet && candyMachine?.program && wallet.publicKey) {
                const mint = anchor.web3.Keypair.generate();
                const mintTxId = (
                    await mintOneToken(candyMachine, wallet.publicKey, mint)
                )[0];

                let status: any = {err: true};
                console.log('mintTxId',mintTxId)
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        'singleGossip',
                        true,
                    );
                }

                if (!status?.err) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });

                    // update front-end amounts
                    displaySuccess(mint.publicKey);
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                }
            }
        } catch (error: any) {
            // TODO: blech:
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };


    useEffect(() => {
        (async () => {
            if (wallet) {
                const balance = await props.connection.getBalance(wallet.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [wallet, props.connection]);
  //   useEffect(() => {
  //     let rateLimit = true;
  //     let interval = setInterval(() => {
  //       rateLimit = true;
  //     }, 100);

  //     const handleMouseMove = (e: MouseEvent) => {
  //       // let x = e.clientX - rect.left;
  //       // let y = e.clientY - rect.top;
  //       const x = e.clientX;
  //       const y = e.clientY;
  //       if (rateLimit) {
  //         document.body.style.setProperty('--x', x + 'px');
  //         document.body.style.setProperty('--y', y + 'px');
  //         rateLimit = false;
  //       }
  //     };
  //     window.addEventListener("mousemove", handleMouseMove);
  //     return () => window.removeEventListener("mousemove", handleMouseMove);
  // }, []);

    useEffect(refreshCandyMachineState, [
        wallet,
        props.candyMachineId,
        props.connection,
        isEnded,
        isPresale
    ]);
    return (
        <main>
            <MainContainer>
                {/* <Card style={{margin: 100}} >
                    <ShimmerTitle>MINT IS LIVE !</ShimmerTitle>
                </Card> */}
                <br/>
                <MintContainer>
                    <DesContainer>
                        <NFT elevation={3}>
                        <a href="https://dahsnft.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
                          <h1 style={{color: '#000'}}>Egg mint</h1>
                          </a>
                          
                          <Wallet>
                              {wallet &&
                                  <WalletAmount>{(balance || 0).toLocaleString()} SOL<ConnectButton/></WalletAmount>}
                          </Wallet>
                          <div style={{textAlign: 'left', display:'flex', justifyContent: 'space-between', height: 0, overflow: 'visible'}}>
                          <Price label={isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? (whitelistPrice + " " + priceLabel) : (price + " " + priceLabel)}  />
                          
                          </div>
                            <br/>
                            <div
                            style={{textAlign: 'center', margin: ''}}>

                                  {/* <model-viewer src="/ape.glb"
                                  ar ar-modes="webxr scene-viewer quick-look" camera-controls autoplay auto-rotate
                                  style={{height: '50vh', width: '100%', '--poster-color': '#ffffff00'}}
                                  environment-image="./ape.hdr"
                                  auto-rotate-delay={1000}
                                  rotation-per-second="1.5rad"
                                  poster="poster.webp"
                                  alt="NFT preview"
                                  exposure="0.75"
                                  ></model-viewer> */}
                                  <img src={'0.png'} alt="NFT preview" style={{width: '100%', maxWidth: '50vh', borderRadius:'50%'}}/>
                                
                                </div>
                            <br/>
                            {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && isBurnToken &&
                              <h3>You own {whitelistTokenBalance} WL mint {whitelistTokenBalance > 1 ? "tokens" : "token" }</h3>}
                            {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && !isBurnToken &&
                              <h3>You are whitelisted and allowed to mint.</h3>}

                            {wallet && isActive && endDate && Date.now() < endDate.getTime() &&
                              <Countdown
                                date={toDate(candyMachine?.state?.endSettings?.number)}
                                onMount={({completed}) => completed && setIsEnded(true)}
                                onComplete={() => {
                                    setIsEnded(true);
                                }}
                                renderer={renderEndDateCounter}
                              />}
                            {wallet && isActive &&
                              <h3>Minted {itemsRedeemed} out of {itemsAvailable} in total</h3>}
                            {wallet && isActive && <BorderLinearProgress variant="determinate"
                                                                         value={100 - (itemsRemaining * 100 / itemsAvailable)}/>}
                            <br/>
                            <MintButtonContainer>
                                {!isActive && !isEnded && candyMachine?.state.goLiveDate && (!isWLOnly || whitelistTokenBalance > 0) ? (
                                    <Countdown
                                        date={toDate(candyMachine?.state.goLiveDate)}
                                        onMount={({completed}) => completed && setIsActive(!isEnded)}
                                        onComplete={() => {
                                            setIsActive(!isEnded);
                                        }}
                                        renderer={renderGoLiveDateCounter}
                                    />) : (
                                    !wallet ? (
                                          <div style={{display: 'flex', flexDirection: desktop ? 'row' :'column', alignItems: 'stretch', gap: desktop ? 0: '5vh', justifyContent: 'space-evenly', marginTop: desktop ? '20px':0}}>
                                            <style>
                                              {`p {
                                                color: black !important;
                                              }
                                              button {
                                                color: black;
                                              }`}
                                              </style>
                                            <ConnectButton style={{
                                              boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                                              fontFamily: 'Manrope',
                                              borderRadius: '22px',
                                              fontSize: '1rem',
                                              lineHeight: '1.75rem',
                                              margin: 0
                                              }}>Connect Wallet</ConnectButton>
                                            {/* <CrossmintPayButton
                                              collectionTitle="Dapper Ape High Society"
                                              collectionDescription="Behold your DAHS Genesis Edition Ape! These apes are dappered from head-to-toe and are fully prepared to take the metaverse by storm!"
                                              collectionPhoto="https://mint.dahsnft.com/logo.png"
                                              clientId="e27fb907-4fc5-4ca9-af05-eaf079865e8a"
                                              style={{backgroundColor: '#ffffff70',
                                              boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                                              color: 'black !important',
                                              fontFamily: 'Manrope',
                                              borderRadius: '22px',
                                              fontSize: '1rem',
                                              lineHeight: '1.75rem'
                                              }}
                                            /> */}
                                          </div>
                                        ) : (!isWLOnly || whitelistTokenBalance > 0) ?
                                        candyMachine?.state.gatekeeper &&
                                        wallet.publicKey &&
                                        wallet.signTransaction ? (
                                            <GatewayProvider
                                                wallet={{
                                                    publicKey:
                                                        wallet.publicKey ||
                                                        new PublicKey(CANDY_MACHINE_PROGRAM),
                                                    //@ts-ignore
                                                    signTransaction: wallet.signTransaction,
                                                }}
                                                // // Replace with following when added
                                                // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                                                gatekeeperNetwork={
                                                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                                } // This is the ignite (captcha) network
                                                /// Don't need this for mainnet
                                                clusterUrl={rpcUrl}
                                                options={{autoShowModal: false}}
                                            >
                                                <MintButton
                                                    candyMachine={candyMachine}
                                                    isMinting={isMinting}
                                                    isActive={isActive}
                                                    isEnded={isEnded}
                                                    isSoldOut={isSoldOut}
                                                    onMint={onMint}
                                                />
                                            </GatewayProvider>
                                        ) : (
                                          <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                           <MintButton
                                                candyMachine={candyMachine}
                                                isMinting={isMinting}
                                                isActive={isActive}
                                                isEnded={isEnded}
                                                isSoldOut={isSoldOut}
                                                onMint={onMint}
                                            />
                                          </div>
                                        ) :
                                        <>
                                          <h1>Mint is private.</h1>
                                        </>
                                        )}
                            </MintButtonContainer>
                            <br/>
                            {wallet && isActive && solanaExplorerLink &&
                              <SolExplorerLink href={solanaExplorerLink} target="_blank">View on Solscan</SolExplorerLink>}
                        </NFT>
                        
                        {nftMetadatas.map((nft, key)=>
                        <NFT elevation={3}key={key}>
                          <h2 style={{color:'#000'}}>{nft.name}</h2>
                          <div style={{display:'flex', alignItems: 'center', flexDirection: 'column'}}>
                          <ImgWithProgress url={nft.image}/>
                          </div>
                        </NFT>
                        )}
                        
                    </DesContainer>
                </MintContainer>
            </MainContainer>
            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({...alertState, open: false})}
            >
                <Alert
                    onClose={() => setAlertState({...alertState, open: false})}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </main>
    );
};

export default Home;
